import type { SelectProps } from '@iheartradio/web.companion';
import { Select } from '@iheartradio/web.companion';

export function SelectField({
  children,
  ...props
}: SelectProps<{ key: string; value: string; label: string }>) {
  return (
    <Select
      css={{
        alignItems: 'start',
        display: 'grid',
        flexGrow: 0,
        font: 'inherit',
        minWidth: '16rem',
        paddingLeft: '$2',
        width: 'auto',

        dark: {
          color: '$blue-400',
        },
        light: {
          color: '$blue-600',
        },

        '& > div': {
          padding: 0,
        },

        '@xsmall': {
          '& > span': {
            minHeight: '2.5rem',
          },
        },

        '@large': {
          '& > span': {
            minHeight: '3.1rem',
          },
        },

        '& [data-reach-listbox-arrow]': {
          minHeight: 'inherit',
        },
      }}
      {...props}
      inline
    >
      {children}
    </Select>
  );
}
